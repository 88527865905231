new Vue({
    el: '#app',
    data: {
        logined: false,
        magnification: 3.622,
        screenWidth: document.body.clientWidth / 3.622 + 'px',
        loading: null,
        username: '',
        userTel: '',
        showOut: false,
    },
    methods: {
        loginOut: function() {
            localStorage.clear();
            window.location.reload();
            this.showOut = false;
        },
        toggleShow: function(){
            this.showOut = !this.showOut;
        },
        contact: function() {
            window.location.href = './static/contact.html';
        },
        locChange: function(num) {
            switch (num) {
                case 1:
                    window.location.href = "./static/modelPreview.html?url=https://cfgateway.gbim360.cn/freeserver-pmts/services/share/405c43b9-24cf-4ef4-8814-3e55ceddad8a/pmts/1.0.0/PMTSCapabilities.json?accesskey=38b49843-9d28-4b76-8359-8cbb58dad312"
                    break;
                case 2:
                    window.location.href = "./static/modelPreview.html?url=https://cfgateway.gbim360.cn/freeserver-pmts/services/share/c8d50540-60f1-4cae-a6c5-87ec7524a023/pmts/1.0.0/PMTSCapabilities.json?accesskey=3fa069db-6e31-444d-ac7c-17d304183c44"
                    break;
                case 3:
                    window.location.href = "../freedox/Demos/FdServer.FdPMTSParser.html"
                    break;
                case 4:
                    window.location.href = "./static/modelPreview.html?url=https://cfgateway.gbim360.cn/freeserver-pmts/services/share/294651e8-2ad0-4e93-b812-abd9514302cd/pmts/1.1.0/PMTSCapabilities.json?accesskey=638b0ebe-b085-4a0d-b93d-0bc5ce8b2962"
                    break;
                case 5:
                    window.location.href = "./static/modelPreview.html?url=https://cfgateway.gbim360.cn/freeserver-pmts/services/share/315622b1-9b7d-4346-99b3-9891eff290d6/pmts/1.0.0/PMTSCapabilities.json?accesskey=f7c53433-05c3-45c1-9ee4-f11449e8c2ed"
                    break;
                case 6:
                    window.location.href = "../freedox/Demos/photogrammetry180420.html"
                    break;
            }
        }
    },
    mounted() {
        const that = this;
        window.onresize = () => {
            that.screenWidth = document.body.clientWidth / that.magnification + 'px';
        };
    },
    created() {
        let token = localStorage.getItem('token');
        this.logined = token ? true : false;
        this.userTel = localStorage.getItem('userTel');
        this.username = localStorage.getItem('username');
    }
})
